import React from 'react'
import LayoutStatic from '../../../components/LayoutStatic';
import { Link } from 'gatsby';

const style = {
  button: {
    marginTop: '10px',
    marginRight: '10px',
    width: '45%'
  }
  
}

const IndexPage = () => {
  return (
    <LayoutStatic>
      <div className="card" style={{width: '18rem', margin: 'auto', marginTop: '20px',}} >
        <img src="/img/CheckUp.png" className="card-img-top bootstrap-card-img-top" alt="..." />
        <div className="card-body">
          <h5 style={{ textAlign:"center" }}>3 ขั้นตอนง่ายๆ</h5>
          <p className="card-text" style={{ textAlign:"center" }}>ก็ตรวจสุขภาพที่บ้านได้แล้ว</p>
          </div>

          <img src="/img/CheckUpProcess.png" className="card-img-top bootstrap-card-img-top" alt="..." />

          <div className="card-body">
          {/* <p className="card-text">มาตรการรักษาความปลอดภัย</p>
          <p className="card-text">การแพร่ระบาดของ Covid-19</p>
          <p className="card-text">ผู้ที่จะเข้ารับบริการ</p>
          <p className="card-text">ต้องทำ การคัดกรองโรค ก่อนทุกครั้ง</p> */}

          <p className="card-text" style={{ textAlign:"center" }}>มาตรการรักษาความปลอดภัย
           การป้องกันการแพร่ระบาดของ Covid-19</p>
          <p className="card-text" style={{ textAlign:"center" , color: "red"}}>ผู้เข้ารับบริการต้อง 
          "คัดกรองความเสี่ยง" ก่อนทุกครั้ง</p>

          {/* <Link to="/menu/infant" className="btn btn-primary" style={style.button}>0-11 เดือน</Link>
          <Link to="/menu/child/01" className="btn btn-primary" style={style.button}>อายุ 1 ปี</Link>
          <Link to="/menu/child/02" className="btn btn-primary" style={style.button}>อายุ 2 ปี</Link>
          <Link to="/menu/child/03" className="btn btn-primary" style={style.button}>อายุ 3 ปี</Link>
          <Link to="/menu/child/04" className="btn btn-primary" style={style.button}>อายุ 4 ปี</Link>
          <Link to="/menu/child/05" className="btn btn-primary" style={style.button}>อายุ 5 ปี</Link>
          <Link to="/menu/child/06" className="btn btn-primary" style={style.button}>อายุ 6 ปี</Link>
          <Link to="/menu/child/07" className="btn btn-primary" style={style.button}>อายุ 7 ปี</Link>
          <Link to="/menu/child/08" className="btn btn-primary" style={style.button}>อายุ 8 ปี</Link> */}

          <Link to="/questionnaire?questionnaireId=76" className="btn btn-primary">ขั้นตอนถัดไป ทำแบบคัดกรอง ความเสี่ยง Covid-19</Link>
          <p></p>
          <p style={{ textAlign:"center" }} ><a href="tel:+66830325978">ติดต่อเจ้าหน้าที่</a></p>
        </div>
      </div>
    </LayoutStatic>
  );
}

export default IndexPage

